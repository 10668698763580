import React from 'react'

import { connect } from 'react-redux'
import { openConfirmDialogAction } from '../../state/ui'
import { push } from 'connected-react-router'
import {
  defaultSnackBar,
  errorSnackBar,
  successSnackBar,
  warningSnackBar,
} from '../../state/ui'

import api from '../../db/rest/api'

const services = api.actions

const getDisplayName = (WrappedComponent) => (
  WrappedComponent.displayName || WrappedComponent.name || 'Component'
)

const withService = (serviceName, customQuery = {}) => WrappedComponent => {
  const WithService = React.memo((props) => {
    return (
      <WrappedComponent
        {...props}
      />
    )
  })

  WithService.displayName = `WithService(${getDisplayName(WrappedComponent)})`

  const mapStateToProps = state => ({
    _serviceData: state[serviceName],
  })

  const service = services[serviceName]

  const mapDispatchToProps = dispatch => ({
    _dispatch: (...args) => dispatch(...args),

    _create: (data, params) => dispatch(service.post(
      { ...params, ...customQuery }, { body: JSON.stringify(data) }
    )),
    _update: (id, data) => dispatch(service.put(
      { id, ...customQuery }, { body: JSON.stringify(data) }
    )),
    _patch: (id, data) => dispatch(service.patch(
      { id, ...customQuery }, { body: JSON.stringify(data) }
    )),
    _find: (params) => dispatch(service.get(
      { ...params, ...customQuery }
    )),
    _get: (id, params) => dispatch(service.get(
      { id, ...params, ...customQuery }
    )),
    _remove: (id, params) => dispatch(service.delete(
      { id, ...params, ...customQuery }
    )),
    _reset: () => { },
    _removeWithDialog: (id, params) => (
      dispatch(
        openConfirmDialogAction(
          'Potwierdź usunięcie!',
          'Tej operacji nie da się cofnąć!',
          () => dispatch(service.delete({ id, ...params, ...customQuery }))
        )
      )
    ),

    _getPage: (page, pageSize = 10) => (
      dispatch(
        service.get({
          page: page + 1,
          pageSize,
          ...customQuery,
        })
      )
    ),

    _goToRoute: path => dispatch(push(path)),

    _defaultSnackBar: (...all) => dispatch(defaultSnackBar(...all)),
    _errorSnackBar: (...all) => dispatch(errorSnackBar(...all)),
    _successSnackBar: (...all) => dispatch(successSnackBar(...all)),
    _warningSnackBar: (...all) => dispatch(warningSnackBar(...all)),
  })

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(WithService)
}

export default withService